'use strict';

var color = require('color')
  , hex = require('text-hex');

/**
 * Generate a color for a given name. But be reasonably smart about it by
 * understanding name spaces and coloring each namespace a bit lighter so they
 * still have the same base color as the root.
 *
 * @param {string} namespace The namespace
 * @param {string} [delimiter] The delimiter
 * @returns {string} color
 */
module.exports = function colorspace(namespace, delimiter) {
  var split = namespace.split(delimiter || ':');
  var base = hex(split[0]);

  if (!split.length) return base;

  for (var i = 0, l = split.length - 1; i < l; i++) {
    base = color(base)
    .mix(color(hex(split[i + 1])))
    .saturate(1)
    .hex();
  }

  return base;
};
