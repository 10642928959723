/**
 * An idiot proof logger to be used as default. We've wrapped it in a try/catch
 * statement to ensure the environments without the `console` API do not crash
 * as well as an additional fix for ancient browsers like IE8 where the
 * `console.log` API doesn't have an `apply`, so we need to use the Function's
 * apply functionality to apply the arguments.
 *
 * @param {Object} meta Options of the logger.
 * @param {Array} messages The actuall message that needs to be logged.
 * @public
 */
module.exports = function (meta, messages) {
  //
  // So yea. IE8 doesn't have an apply so we need a work around to puke the
  // arguments in place.
  //
  try { Function.prototype.apply.call(console.log, console, messages); }
  catch (e) {}
}
