var enabled = require('enabled');

/**
 * Creates a new Adapter.
 *
 * @param {Function} fn Function that returns the value.
 * @returns {Function} The adapter logic.
 * @public
 */
module.exports = function create(fn) {
  return function adapter(namespace) {
    try {
      return enabled(namespace, fn());
    } catch (e) { /* Any failure means that we found nothing */ }

    return false;
  };
}
